<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>

        <CCardHeader>
          <div class="row">
            <div class="col-11">Stok Durum Raporu</div>
            <div class="col-1">
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
      
          <CCol col="12">
            <CDataTable
              :items="stocks"
              :fields="fields"
              :loading="loading"
              :items-per-page="15"
              pagination
              clickable-rows
              hover
              v-on:refresh="getStockStatus()"
            />
          </CCol>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'AdCamp',
  data() {
    return {
      fields: [
        {key: 'ptID', label: 'Ürün ID', _style: 'font-size:12px'},
        {key: 'ptName', label: 'Ürün Adı', _style: 'font-size:12px'},
        {key: 'kaftAmount', label: 'Kaft Adeti', _style: 'font-size:12px'},
        {key: 'logisticsAmount', label: 'Lojistik Adeti', _style: 'font-size:12px'},
        {key: 'skuOutOfStockRatio', label: 'Stok Dışı SKU Oranı (%)', _style: 'font-size:12px'},
      ],
    }
  },
  computed: {
    stocks: function(){
      return this.$store.getters.stockReports
    },
    loading: function(){
      return this.$store.getters.reportLoading
    },
  },
  methods: {

    async getStockStatus() {
      
      this.$store.dispatch('stockStatus_report')

    },

  },
  created() {
      this.getStockStatus();
  }
}
</script>
